import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import TourCard from "../components/common/TourCard/tour-card";
import Container from "../components/layout/Container/container";
import ContainerNarrow from "../components/layout/ContainerNarrow/container-narrow";
import Layout from "../components/layout/Main/main";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";
import Paragraph from "../components/typography/Paragraph/paragraph";

const Tours = ({ data }) => {
    const getOneTour = (index, col) => {
        const { frontmatter } = data.allMarkdownRemark.nodes[index];
        return (
            <Box col={col} mb={4} px={3} key={frontmatter.id}>
                <TourCard
                    isNew={frontmatter.new}
                    picture={frontmatter.featuredImage.childImageSharp.fluid}
                    title={frontmatter.title}
                    description={frontmatter.subTitle}
                    to={frontmatter.path}
                />
            </Box>
        );
    };

    return (
        <Layout>
            <SEO
                title="See all tours"
                description="Paris is always revealing the old and always seeking the new. Paris glows with “a patina” of shared lives and each generation makes Paris its own."
            />
            <Container>
                <Box my={5}>
                    <Headline textAlign="center">Tours</Headline>
                </Box>
            </Container>
            <ContainerNarrow mb={5}>
                <Paragraph textAlign="center">
                    <strong>There is magic in the name of Paris</strong>. It is
                    this very old magic that our Paris Insight walks reveal to
                    you. Where the “tourism industry” takes you mainly to the
                    Eiffel Tower, the Louvre, the Invalides, Notre-Dame
                    Cathedral, the Arc de Triomphe, Champs-Elysées,
                    Versailles... Virginia (without ignoring the monuments which
                    make Paris famous) immerses you in the{" "}
                    <strong>history</strong> of Paris, its{" "}
                    <strong>past and present</strong> life, and helps you find
                    what turned this city into{" "}
                    <strong>a magnet for generations of artists</strong> as well
                    as ordinary visitors.
                </Paragraph>
            </ContainerNarrow>
            <Container px={0}>
                <Box row mb={3}>
                    {getOneTour(0, { xs: 1, sm: 1, md: 1, xl: 1 / 2 })}
                    {getOneTour(1, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(2, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                </Box>
            </Container>
            <ContainerNarrow mb={5}>
                <Paragraph textAlign="center">
                    <strong>Paris is a myth.</strong> The myth can become a
                    reality, but a crowded tour bus or an afternoon amid throngs
                    of other tourists can easily sour the dream. Paris Insight,
                    with Virginia as your guide, will immerse you in the{" "}
                    <strong>very depths of the city</strong>, reveal its music
                    to you and make audible the voices of the famous people who
                    fell in love with it and contributed to its fame.
                </Paragraph>
            </ContainerNarrow>
            <Container px={0}>
                <Box row mb={3}>
                    {getOneTour(3, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(4, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(5, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(6, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                </Box>
            </Container>
            <ContainerNarrow mb={5}>
                <Paragraph textAlign="center">
                    Virginia’s walking tours are more than walk-by descriptions
                    of buildings and monuments. Her knowledge of Paris,{" "}
                    <strong>her passion and talent as a storyteller</strong>{" "}
                    help you find that <em>je ne sais quoi</em> that you have
                    been dreaming of, searched for and expected to find on
                    arriving in the city.
                </Paragraph>
            </ContainerNarrow>
            <Container px={0}>
                <Box row mb={5}>
                    {getOneTour(7, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(8, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(9, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                    {getOneTour(10, { xs: 1, sm: 1 / 2, md: 1 / 2, xl: 1 / 4 })}
                </Box>
            </Container>
        </Layout>
    );
};

Tours.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ToursPageQuery {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "tour" } } }
            sort: { order: ASC, fields: frontmatter___order }
        ) {
            nodes {
                frontmatter {
                    id
                    title
                    subTitle
                    path
                    new
                    featuredImage {
                        childImageSharp {
                            fluid(maxWidth: 400) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Tours;
