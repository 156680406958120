import PropTypes from "prop-types";
import React from "react";

import S from "./tour-card.style";

const TourCard = ({ picture, description, title, to, isNew }) => (
    <S.main to={to}>
        <S.container>
            <S.picture fluid={picture} alt={title} />
            <S.gradient />
            <S.overlayBackground />
            <S.overlay>
                <S.overlayContent>
                    <S.description>{description}</S.description>
                    <S.title>{title}</S.title>
                    <S.learnMore>Learn more</S.learnMore>
                </S.overlayContent>
            </S.overlay>
        </S.container>
        {isNew && <S.new>New</S.new>}
    </S.main>
);

TourCard.propTypes = {
    picture: PropTypes.object,
    description: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
    isNew: PropTypes.bool,
};

export default TourCard;
