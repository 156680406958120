import styled, { css, th } from "@xstyled/styled-components";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { darken } from "polished";

const S = {};

const overlayStyle = `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const textStyle = css`
    text-shadow: 0px 0px 12px ${th("colors.dark")},
        0px 0px 5px ${th("colors.dark")};
    width: 100%;
    color: light;
    text-align: right;
`;

S.container = styled.div`
    ${overlayStyle};
    overflow: hidden;
    border-radius: 8;
`;

S.overlayBackground = styled.div`
    ${overlayStyle};
    opacity: 0;
    background-color: dark;
    transition: opacity 0.25s ease-in-out;
`;

S.overlayContent = styled.div`
    text-align: right;
    transform: translateY(60px);
    transition: transform 0.25s ease-in-out;
`;

/* FIXME: create constants for font families */
S.description = styled.p`
    ${textStyle};
    margin: 0 0 1;
    font-size: 3;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    font-family: "Nunito", sans-serif;
`;

S.picture = styled(GatsbyImage)`
    height: 100%;
    width: 100%;
    transition: transform 1.5s ease-in-out;
`;

S.main = styled(Link)`
    display: block;
    text-decoration: none;
    height: 300px;
    width: auto;
    position: relative;
    box-shadow: regular;

    &:hover {
        ${S.overlayContent} {
            transform: translateY(0);
        }

        ${S.description} {
            opacity: 1;
        }
        ${S.overlayBackground} {
            opacity: 0.5;
        }
        ${S.picture} {
            transform: scale(1.05);
        }
    }
`;

S.gradient = styled.div`
    ${overlayStyle};
    opacity: 0.7;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
`;

S.overlay = styled.div`
    ${overlayStyle};
    display: flex;
    flex-direction: column;
    padding: 4;
    justify-content: flex-end;
`;

/* FIXME: create constants for font families */
S.title = styled.h2`
    ${textStyle};
    font-size: 4;
    font-weight: bold;
    margin: 0 0 4;
    font-family: "Josefin Sans", sans-serif;
`;

/* FIXME: create constants for font families */
S.learnMore = styled.p`
    margin: 0;
    background-color: primary;
    color: light;
    display: inline;
    padding: 2 3;
    border-radius: 5;
    font-family: "Josefin Sans", sans-serif;
    font-size: 3;
    text-shadow: 0px 0px 10px
        ${(props) => darken(0.2, th("colors.primary")(props))};
`;

S.new = styled.div`
    position: absolute;
    top: -8px;
    left: -8px;
    background-color: primary;
    color: light;
    padding: 0 6px;
    border-radius: 5;
    font-size: 2;
    letter-spacing: 1;
    text-transform: uppercase;
    font-weight: bold;
`;

export default S;
